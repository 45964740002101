@use '@queries' as *;
@use '@fixed-size' as *;
@use '@typography' as *;

.wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  max-width: 1023px;

  padding: 14px;
  @include min-744-break {
    padding: 16px 28px;
  }

  .body {
    display: flex;
    flex: 1;

    flex-direction: column;
    gap: 14px;

    padding-top: 56px;

    @include min-428-break {
      padding-top: 126px;
    }

    @include min-744-break {
      padding-top: 236px;
    }

    @include min-744-break {
      gap: 18px;
    }

    .titles {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;

      .title {
        color: var(--cst-side-bar-shop-text);
        @include typography-m;
        @include weight-semi-bold;

        @include min-428-break {
          @include typography-xl;
        }
      }

      .subtitle {
        color: var(--cst-side-bar-items-text-default);
        @include typography-s;
        @include weight-medium;

        @include min-428-break {
          @include typography-m;
        }
      }
    }
  }

  .footer {
    margin-top: auto;

    .action-btn {
      border-radius: var(--cornerradius8);

      span[id='button-span-text'] {
        @include typography-s;
        @include weight-semi-bold;

        @include min-428-break {
          @include typography-base;
        }
      }

      @include fixedSize(100%, 38px);

      @include min-428-break {
        @include fixedSize(100%, 46px);
        border-radius: var(--cornerradius10);
      }

      @include min-744-break {
        @include fixedSize(100%, 52px);
        border-radius: var(--cornerradius12);
      }

      .icon {
        @include singleFixedSize(18px);

        @include min-428-break {
          @include singleFixedSize(20px);
        }

        @include min-744-break {
          @include singleFixedSize(22px);
        }
      }
    }
  }
}
