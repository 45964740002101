@use '@queries' as *;
@use '@typography' as *;
@use '@fixed-size' as *;

.container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 80px 0;

  @include min-1440-break {
    padding: 90px 0;
  }

  @include min-1920-break {
    padding: 100px 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    align-items: center;

    @include min-1440-break {
      gap: 20px;
    }

    @include min-1920-break {
      gap: 24px;
    }

    .titles {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;

      @include min-1440-break {
        gap: 8px;
      }

      @include min-1920-break {
        gap: 10px;
      }

      .title {
        color: var(--cst-side-bar-shop-text);
        @include typography-m;
        @include weight-semi-bold;

        @include min-1440-break {
          @include typography-l;
        }

        @include min-1920-break {
          @include typography-xl;
        }
      }

      .subtitle {
        color: var(--cst-side-bar-items-text-default);
        @include typography-s;
        @include weight-regular;

        @include min-1440-break {
          @include typography-base;
        }

        @include min-1920-break {
          @include typography-m;
        }
      }
    }

    .action-btn {
      border-radius: var(--cornerradius10);

      span[id='button-span-text'] {
        @include typography-base;
        @include weight-semi-bold;

        @include min-1440-break {
          @include typography-base;
        }
      }

      @include fixedSize(260px, 46px);

      @include min-1440-break {
        @include fixedSize(320px, 54px);
        border-radius: var(--cornerradius12);
      }

      @include min-1920-break {
        @include fixedSize(380px, 54px);
      }

      .icon {
        @include singleFixedSize(20px);

        @include min-428-break {
          @include singleFixedSize(22px);
        }

        @include min-744-break {
          @include singleFixedSize(22px);
        }
      }
    }
  }
}
